import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

const Home = lazy(() => import("../Pages/Home"));
const About = lazy(() => import("../Pages/About"));

const Services = lazy(() => import("../Pages/Services"));
const Markets = lazy(() => import("../Pages/Markets"));
const News = lazy(() => import("../Pages/News"));
const NewsDetail = lazy(() => import("../Pages/NewsDetail"));
const Careers = lazy(() => import("../Pages/Careers"));
const CareersDetail = lazy(() => import("../Pages/CareersDetail"));
const Contact = lazy(() => import("../Pages/Contact"));
const Testimonials = lazy(() => import("../Pages/Testimonials"));

const MainRoutes = () => {
  return (
    <Suspense>
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/markets" element={<Markets />} />
        <Route path="/news" element={<News />} />
        <Route path="/news/:id" element={<NewsDetail />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/careers/:id" element={<CareersDetail />} />
        {/* <Route path="/careers/testimonials" element={<Testimonials />} /> */}
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/demo" element={<Demo />} /> */}
      </Routes>
    </Suspense>
  );
};

export default MainRoutes;
