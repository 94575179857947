import "./App.css";

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import MainRoutes from "./js/Navigation/MainRoutes";

import Header from "./js/Components/Header";

function App() {
  React.useEffect(() => {
    // Matomo initialization
    window._mtm = window._mtm || [];
    window._mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });

    // Script insertion
    const scriptId = "matomo-script";
    if (!document.getElementById(scriptId)) {
      var d = document,
        g = d.createElement("script"),
        s = d.getElementsByTagName("script")[0];
      g.async = true;
      g.id = scriptId;
      g.src = "https://analytics.variass.nl/js/container_67i8aiaM.js";
      s.parentNode.insertBefore(g, s);
    }
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <header>
          <Header />
        </header>
        <main>
          <MainRoutes />
        </main>
      </Router>
    </HelmetProvider>
  );
}

export default App;
