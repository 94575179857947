import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en.json";
import translationNL from "./locales/nl.json";

const loadTranslations = async (locale) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_STRAPI_API_HOST}/api/site-text?locale=${locale}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
      },
    });
    const data = await response.json();
    return data.data.attributes.siteText;
  } catch (error) {
    console.error("Failed to load translations:", error);
    return {};
  }
};

const resources = {
  nl: {
    translation: translationNL,
  },
  en: {
    translation: translationEN,
  },
};

//Read from local storage to enable language switch
// var language = localStorage.getItem("language") ?? "en";
var language = "nl";

i18n.use(initReactI18next).init({
  fallbackLng: "nl",
  resources,
  lng: language,

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

loadTranslations(i18n.language).then((translations) => {
  i18n.addResourceBundle(i18n.language, "translation", translations, true, true);
});

export { loadTranslations };
export default i18n;
