import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import Hamburger from "hamburger-react";
import { useTranslation } from "react-i18next";

import LanguageSelector from "./LanguageSelector";

import Logo from "../../assets/logos/logo_white_hor.svg";

const Header = () => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [activeMenus, setActiveMenus] = useState({});
  const [openMenus, setOpenMenus] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [hideHome, setHideHome] = useState(window.innerWidth < 1200);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
      setHideHome(window.innerWidth < 1140);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const menus = useMemo(
    () => [
      // ...(!hideHome ? [{ key: "home", path: "/", title: t("home_title"), isNavLink: true }] : []),
      {
        key: "about",
        path: "/about",
        title: t("about_title"),
        subItems: [
          { path: "/about#esg", title: t("about_sustainability_title") },
          { path: "/about#certifications", title: t("about_certifications_header_title") },
          { path: "/about#partofmetisgroup", title: t("about_metis_header_title") },
          { path: "/about#variassgroup", title: t("about_variassgroup_header_title") },
        ],
      },
      {
        key: "markets",
        path: "/markets",
        title: t("markets_title"),
        subItems: [
          { path: "/markets#medical", title: t("markets_medical_title") },
          { path: "/markets#industry", title: t("markets_industry_title") },
          { path: "/markets#defenceSecurity", title: t("markets_defenceSecurity_title") },
        ],
      },
      {
        key: "services",
        path: "/services",
        title: t("services_title"),
        subItems: [
          { path: "/services?service=innovate#services-menu", title: t("services_innovate_title") },
          { path: "/services?service=scaleup#services-menu", title: t("services_scaleup_title") },
          { path: "/services?service=perform#services-menu", title: t("services_perform_title") },
          { path: "/services?service=reuse#services-menu", title: t("services_reuse_title") },
        ],
      },
      { key: "news", path: "/news", title: t("news_title"), isNavLink: true },
      { key: "careers", path: "/careers", title: t("careers_title"), isNavLink: true },
      { key: "contact", path: "/contact", title: t("contact_title"), isNavLink: true },
    ],
    [t]
  );

  useEffect(() => {
    const updatedActiveMenus = {};
    menus.forEach((menu) => {
      updatedActiveMenus[menu.key] = location.pathname.includes(menu.path);
    });
    setActiveMenus(updatedActiveMenus);
  }, [location, menus]);

  const handleNavigate = (path) => {
    navigate(path);
    setOpen(false);
    setOpenMenus({});
  };

  const toggleDropdown = (menuKey, forceClose = false) => {
    setOpenMenus((prev) => ({ ...prev, [menuKey]: forceClose ? false : !prev[menuKey] }));
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("scrolled");
    } else {
      document.body.classList.remove("scrolled");
    }
  }, [isOpen]);

  const renderMenuItems = (menu) => {
    if (menu.isNavLink || isMobile) {
      return (
        <NavLink key={menu.key} to={menu.path} className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} onClick={() => setOpen(false)}>
          {menu.title}
        </NavLink>
      );
    } else {
      return (
        <NavDropdown
          key={menu.key}
          title={<div onClick={() => handleNavigate(menu.path)}>{menu.title}</div>}
          className={activeMenus[menu.key] ? "active" : ""}
          show={openMenus[menu.key]}
          onToggle={() => toggleDropdown(menu.key)}
          onMouseEnter={() => setOpenMenus((prev) => ({ ...prev, [menu.key]: true }))}
          onMouseLeave={() => setOpenMenus((prev) => ({ ...prev, [menu.key]: false }))}
        >
          {menu.subItems.map((item) => (
            <NavDropdown.Item
              key={item.path}
              onClick={(e) => {
                e.preventDefault();
                handleNavigate(item.path);
              }}
            >
              {item.title}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      );
    }
  };

  // Determine if the current route is the home page
  const isHomePage = location.pathname === "/";

  return (
    // <Navbar id="navbar-menu" expanded={isOpen} bg="#007fb5" expand="lg" fixed={isHomePage ? null : "top"} className={isHomePage ? "transparent" : "scrolled"}>
    <Navbar
      id="navbar-menu"
      expanded={isOpen}
      bg="#007fb5"
      expand="lg"
      fixed={isHomePage ? null : "top"}
      className={`${isHomePage ? "transparent" : "scrolled"} ${isOpen && isMobile ? "scrolled" : ""}`}
    >
      <Container>
        <Navbar.Brand as={NavLink} to="/">
          <img src={Logo} alt="Company Logo" className="variass-logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav">
          <Hamburger toggled={isOpen} toggle={setOpen} />
        </Navbar.Toggle>
        <Navbar.Collapse id="navbar-nav">
          <Nav className="mx-auto text-center">
            {menus.map(renderMenuItems)}
            {/* Read from local storage to enable language switch */}
            {/* <LanguageSelector></LanguageSelector> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
